<template>
  <div
    :style="cssProps"
    :class="{'wrapper': !isMobile, 'wrapper-mobile': isMobile}"
    class="d-flex justify-space-between align-center h-full"
  >
    <div
      v-if="!isMobile"
      class="markero-logo col-6 pa-0 px-10"
    >
      <v-img
        alt="Company Registration Logo"
        :src="require('@/../public/img/markero-registration.png')"
        contain
      />
    </div>

    <div
      :class="{'content-wrapper': !isMobile, 'content-wrapper-mobile': isMobile}"
      class="h-full col-md-6 flex-column d-flex justify-space-between"
    >
      <div class="flex-1 register px-2 px-sm-0 mx-auto col-sm-10 col-lg-6 inner-container">
        <div class="row justify-center text-center">
          <div class="content">
            <div class="text-center pb-8">
              <h2 class="register-title">
                {{ $t('register.title') }}
              </h2>
            </div>
            <div>
              <v-form
                ref="signupForm"
                @submit.prevent="signup"
              >
                <v-text-field
                  v-model.trim="email"
                  :label="$t('signup.labels.email')"
                  type="email"
                  outlined
                  hide-details="auto"
                  class="email pb-5"
                  prepend-inner-icon="mdi-email-outline"
                  :rules="[rules.email, rules.required]"
                  required
                  validate-on-blur
                />

                <v-text-field
                  v-model="password"
                  :label="$t('signup.labels.password')"
                  :type="showPassword ? 'text' : 'password'"
                  hide-details="auto"
                  outlined
                  prepend-inner-icon="mdi-lock-outline"
                  :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                  class="password pb-8"
                  :rules="[rules.required]"
                  required
                  validate-on-blur
                  @click:append="showPassword = !showPassword"
                />
                <div class="d-flex justify-space-between pb-8">
                  <v-checkbox
                    v-model="checkbox"
                    :rules="[rules.required]"
                  >
                    <template v-slot:label>
                      <span class="agree-on-terms">
                        <Terms />
                      </span>
                    </template>
                  </v-checkbox>
                </div>
                <div
                  class="px-0 d-flex flex-column"
                >
                  <v-expand-transition>
                    <v-alert
                      v-if="error"
                      type="error"
                      dense
                      tile
                      text
                      class="my-4"
                    >
                      <span v-html="$t(getErrorMessage(error))" />
                    </v-alert>
                  </v-expand-transition>

                  <LoadingButton
                    :color="$vuetify.theme.themes.light.markero.blue"
                    class="px-10 register-btn"
                    :loading="submitting"
                    type="submit"
                  >
                    {{ $t('buttons.signup.register-now') }}
                  </LoadingButton>
                </div>
              </v-form>
            </div>

            <div class="mt-8 d-flex justify-center">
              <h4 class="account-exists">
                {{ $t('signup.account-exists') }}
              </h4>
              <a
                href="/auth/login"
                :style="infoColor"
                class="ml-2 account-exists login-link"
              >{{ $t('login.form.submit') }}</a>
            </div>
          </div>
        </div>
      </div>
      <div class="flex-1 terms-wrapper mb-2 mx-auto">
        <div
          :class="{'terms': !isMobile, 'terms-mobile ma-2': isMobile}"
          class=" justify-center d-flex flex-1 flex-wrap"
        >
          <IubendaDocumentLink
            type="terms"
            class="mx-1 mx-sm-2"
            @click.stop
          />
          <IubendaDocumentLink
            class="mx-1 mx-sm-2"
            type="privacyPolicy"
            @click.stop
          /><br>
          <IubendaConsentLink class="mx-1 mx-sm-2" />
          <a
            :href="imprintUrl"
            target="_blank"
            class="mx-1 mx-sm-2"
            rel="noopener noreferrer"
          >{{ $t('navigation.labels.imprint') }}</a>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import featureMixin from '@/mixins/feature'
import brandingMixin from '@/mixins/branding'
import REGISTER_USER from '@/modules/auth/Register/queries/registerUser.gql'
import { isValidPassword } from '@/lib/password'
import { email, required } from '@/lib/validation'
import LoadingButton from '@/components/loading-button.vue'
import Terms from '@/modules/auth/Register/Terms.vue'
import IubendaDocumentLink from '@/modules/iubenda/DocumentLink.vue'
import IubendaConsentLink from '@/modules/iubenda/ConsentPreferencesLink.vue'

export default {
  components: {
    IubendaConsentLink,
    IubendaDocumentLink,
    Terms,
    LoadingButton
  },
  mixins: [featureMixin, brandingMixin],
  data () {
    return {
      submitting: false,
      email: '',
      password: '',
      error: null,
      showPassword: false,
      checkbox: false
    }
  },
  computed: {
    cssProps () {
      return {
        '--mid-yellow': this.$vuetify.theme.themes.light.markero.midYellow,
        '--dark-blue': this.$vuetify.theme.themes.light.markero.darkBlue,
        '--dark-grey': this.$vuetify.theme.themes.light.markero.darkGrey,
        '--light-orange': this.$vuetify.theme.themes.light.markero.lightOrange
      }
    },
    isMobile () {
      return this.$vuetify.breakpoint.mdAndDown
    },
    infoColor () {
      return {
        color: this.$vuetify.theme.themes.light.markero.blue
      }
    },
    title () {
      return this.$route.params.token ? this.$t('signup.title-free') : this.$t('signup.title-free-trial')
    },
    formReady () {
      return (this.email.length && this.password.length)
    },
    errorMessages () {
      return {
        emptyFields: 'alerts.signup.empty-fields',
        invalidToken: 'alerts.signup.invalid-token',
        invalidAgreement: 'alerts.signup.invalid-agreement',
        invalidPassword: 'alerts.signup.invalid-password',
        userExists: 'alerts.signup.user-exists',
        unknown: 'alerts.signup.unknown'
      }
    }
  },
  created () {
    this.rules = { email, required }
  },
  apollo: {
    $client: 'public'
  },
  methods: {
    async signup (event) {
      event.preventDefault()
      this.error = null
      const isValid = await this.$refs.signupForm.validate()
      if (isValid && this.formReady && this.checkbox) {
        if (!isValidPassword(this.password)) {
          this.error = 'invalidPassword'
          return
        }

        this.submitting = true

        try {
          const input = {
            email: this.email,
            password: this.password,
            locale: this.$i18n.locale
          }

          if (this.$route.query?.companyId) { input.companyId = this.$route.query.companyId }

          const { data } = await this.$apollo.mutate({
            mutation: REGISTER_USER,
            variables: {
              input: input
            }
          })
          this.submitting = false
          if (data) {
            sessionStorage.removeItem('anamnesis')
            await this.$auth.login(this.email, this.password)
          }
        } catch (error) {
          this.error = 'unknown'
          this.submitting = false
        }
      }
    },
    getErrorMessage (code) {
      return this.errorMessages[code] || this.errorMessages.unknown
    }
  }
}
</script>

<style scoped>
/* Wrapper Styles */
.wrapper, .wrapper-mobile {
  background-color: white;
}

.wrapper, .markero-logo {
  height: 100vh;
}

.wrapper-mobile {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

/* Content Wrapper Styles */
.content-wrapper {
  display: flex;
  flex-direction: column !important;
  min-height: 100vh;
}

.register {
  margin-top: 15%;
}

/* Markero Logo Styles */
.markero-logo {
  background-color: var(--mid-yellow);
  display: flex;
  justify-content: center;
  align-items: flex-end;
}

/* Register Styles */
.register-title,
.email,
.password,
.account-exists,
.terms,
.agree-on-terms,
.register-btn {
  color: #252525;
  font-family: 'Poppins', sans-serif;
}

.register-title {
  font-size: 28px;
  font-weight: 700;
  line-height: 34px;
}

.account-exists, .terms {
  font-weight: 400;
  line-height: normal;
}

.account-exists {
  font-size: 16px;
}

.login-link {
  text-decoration: none;
  font-weight: 700;
}

.login-link:hover {
  color: var(--dark-blue) !important;
}

.inner-container, .terms-wrapper {
  max-width: 500px;
}

.terms, .terms-mobile {
  font-size: 14px;
}

.terms-mobile {
  margin-top: 80%;
}

.terms-error .terms-mobile {
  margin-top: 70%;
}

.register-btn {
  font-family: 'Poppins', sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  color: white;
  height: fit-content !important;
  padding: 14px 34px !important;
  border-radius: 10px;
  box-shadow: 5px 5px 15px 0 rgba(47, 22, 232, 0.25);
  text-transform: none;
}

.content {
  width: 100%;
  max-width: 450px;
}

.agree-on-terms {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-align: left;
  margin-top: 0;
}

/* Vuetify Specific Overrides */

::v-deep .v-input--selection-controls__input {
  margin-top: -10px !important;
}
::v-deep .theme--light.v-icon {
  color: var(--light-orange) !important;
}

::v-deep .v-input--selection-controls {
   margin-top: 0 !important;
   padding-top: 0 !important;
}

::v-deep .v-application--is-ltr .v-input--selection-controls__input {
  margin-right: 0 !important;
}

::v-deep fieldset {
  background-color: white;
}

::v-deep .theme--light.v-icon {
  z-index: 99;
  color: rgba(0, 0, 0, 0.54);
}

::v-deep .theme--light.v-text-field--outlined:not(.v-input--is-focused):not(.v-input--has-state) > .v-input__control > .v-input__slot fieldset {
  color: #E4E4E4;
}

::v-deep .theme--light.v-input input {
  color: var(--dark-grey);
}

::v-deep .v-text-field--enclosed .v-input__prepend-inner {
  margin: 12px 0;
}

::v-deep .v-text-field--outlined .v-label {
  top: 15px;
}

::v-deep .v-text-field--enclosed .v-input__append-inner {
  margin-top: 14px !important;
}

::v-deep .v-text-field--outlined > .v-input__control > .v-input__slot {
  min-height: 30px;
}

::v-deep .theme--light.v-btn:hover::before {
  background-color: var(--dark-blue);
}

.error--text {
  padding-bottom: 0 !important;
}

::v-deep .v-text-field.v-text-field--enclosed .v-text-field__details {
  margin-bottom: 2px !important;
  padding-bottom: 2px !important;
}

::v-deep .v-text-field.v-text-field--enclosed:not(.v-text-field--rounded) > .v-input__control > .v-input__slot {
  margin-bottom: 0 !important;
}

::v-deep .password.v-text-field.v-text-field--enclosed .v-text-field__details {
  margin-bottom: 15px !important;
}

@media only screen and (max-width: 450px) {
  .register {
    margin-top: 0;
  }
}

</style>
